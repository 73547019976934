import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getFleetList, getFleetListAdmin, getFleetListUser, robotListfleetId } from '../API/index'
import { FleetData, fleetlatitudeaction, fleetlogitudeaction, robotDataAction } from '../redux/actions'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Battery80Icon from '@mui/icons-material/Battery80';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Header from './Header'
import IndividualRobot from './IndividualRobot'
import $ from 'jquery'
import ReactAudioPlayer from 'react-audio-player';
function SelectFleet() {

 const dispatch= useDispatch() 
 const [fleetlist, setfleetlist] =useState() 
 const [robotmsg, setrobotmsg] =useState([])
 const navigate= useNavigate()
 const email = localStorage.getItem('useremail')
 const isLogged=localStorage.getItem('Isloggedin')
 const token = localStorage.getItem('token')
 let audioaudio

 const wholeData = useLocation()
 const robotMsgData = useSelector(state=>state.robotMessageReducer)
 let teleop=[] 
 const [showheading, setshowheading] =useState(false)
const useremail =localStorage.getItem('useremail')
var role = localStorage.getItem('role')
  useEffect(()=>{
     if(token){
      if(role==="admin"){
        getFleetListAdmin(email,token)
        .then((res)=>{ 
          console.log('res.data',res?.data)
          setfleetlist(res?.data?.fleet)
        })
        .catch((err)=>{
         
        })
      }
      else{
        getFleetListUser(email,token)
        .then((res)=>{ 
          console.log('res.data',res?.data)
          setfleetlist(res?.data?.fleet)
         })
        .catch((err)=>{
            
        })
      } 
     }
  },[])

 useEffect(()=>{
   
  if(token==='' || token=== "null" ||  token=== null || token===undefined || useremail===null ||useremail==='null'||useremail ===undefined ){
    window.location.replace('/')
   }
    else{

      setfleetlist(wholeData?.state?.name?.fleet)
    }
  robotMsgData?.robotmsgData?.map((item)=>{
    return teleop.push(item.teleop)
  })

  if(teleop.includes("YES")){
      setshowheading(true)
      document.addEventListener('click', function(){
      document.getElementById('playaudio').play();
      }) 
      // audioaudio.audioEl.onPlay()
  }
  else{
    setshowheading(false)
  }


  // setTimeout(()=>{
  //  pausebeepsound()
  // }, 4000)
 
  },[wholeData,robotMsgData])
// useEffect(()=>{
//   setInterval(()=>{
//     window.location.reload()
//   } , 10000)
// })

 const robotlistRedux=  useSelector(state=> state.robotlistReducer)
 const getrobotStatus=(item,data)=>{
     switch(item.robotStatus){
      case "AVAILABLE":
        return  <p className="text-left m-0 p-color-green">Available</p>
      case "UNAVAILABLE":
        return  <p className="text-left m-0 p-color-red"> UnAvailable</p>  
      case "CHARGING":
        return  <p className="text-left m-0 p-color-green">Charging</p>
      case "ERROR":
        robotmsg.push({
          fleetId:data?.fleetId,
          fleetName:data?.fleetName,
          robotStatus:item.robotStatus,
          robotId:item.robotId,
          robotBattery:item.robotBattery,
          latitude:data?.latitude,
          longitude:data?.longitude,
          teleop:item.teleop
        })
        return <p className="text-left m-0 p-color-red"> Error</p>
      case "Need Help":
        robotmsg.push({
          fleetId:data?.fleetId,
          fleetName:data?.fleetName,
          robotStatus:item.robotStatus,
          robotId:item.robotId,
          robotBattery:item.robotBattery,
          latitude:data?.latitude,
          longitude:data?.longitude,
          teleop:item.teleop
        })
       return <p className="text-left m-0 p-color-red"> Need Help</p>
      default:
       return <p className="text-left m-0 p-color-red"> {item.robotStatus}</p>
    }
 }
 const handlerobotdata=(robotdata)=>{
  //  console.log('RobotDatarobotdata',robotdata)
 //  dispatch(robotDataAction(robotdata[0]))
  localStorage.setItem('fleetId',robotdata?.fleetId)
  navigate(`/dashboard/${robotdata?.robotId}` ,{state:{name:robotdata}})
} 

  return (
    
    <>
   <Header/>
   {/* {console.log('fleetlist',fleetlist)} */}
    <div className='container-fluid SelectFleetPage-wrapper-Main'>
    
          {/* {console.log('wholeData',wholeData)}   */}
       <div>
      {robotMsgData?.robotmsgData?.length && showheading>0 ?<h5 className='Select-Page-Robot-Message-Heading'> ROBOT MESSAGE</h5> :null}
       <div className='SelectFleet-page-Error-box-wrapper'> 
        {robotMsgData?.robotmsgData?.map((data)=>{
         if(data.teleop==="YES"){
          return  <div className="SelectFleetPage-content-wrapper-Main border-red" onClick={()=>handlerobotdata(data)}> 
           {/* <audio preload="auto" src="/static/media/alert.aa9b8554cbeaf7968e81.wav" autoplay="autoplay" loop id="playaudio"></audio> */}
                    <audio preload="auto" autoplay="autoplay" loop id="playaudio">
                      <source src="/static/media/alert.aa9b8554cbeaf7968e81.wav" type="audio/wav"></source>
                  </audio>
          <div className='border-blue-top SelectFleetPage-inner-li-div d-flex-center'> 
            <button className="Error-state-teleop-ack-button"> Teleop Ack</button>
                <div>
                  <div> 
                  <h4 className="Select-page-fleetName"> {data.fleetName}</h4>
                </div>
                <div>
                    <p className="SelectFleetPage-inner-li-div-robot-id" style={{color:'white'}}>{data.robotId}</p>
                    {getrobotStatus(data, null)}
              </div>
              </div> 
              {/* <ReactAudioPlayer src="/static/media/alert.aa9b8554cbeaf7968e81.wav"
                                autoPlay
                                controls
                                ref={(element) => { audioaudio = element; }} /> */}
                <div className="dashboard-left-side-div-one-inner-div"> 
                    <div  className="dashboard-left-side-div-one-wrapper-battery-div">
                    <div class="battery-icon-css">
                <span style={{width:'30px', border:'1px solid #fff', borderRadius: '4px', padding:'2px', height: '14px', display: 'inline-block'}}>
                   <strong style={{background: parseInt(data.robotBattery).toFixed(0)<=20 ?'#E4261C':'rgb(113, 207, 98)', width:`${parseInt(data.robotBattery).toFixed(0)}%`, display:'block', height:'8px'}}></strong>
                 </span> 
                 </div>   
                        <p style={{fontSize:'10px'}}>{parseInt(data.robotBattery).toFixed(0)}%</p> 
                    </div> 
                      <span style={{background: '#fff', width:'3px', height:'5px',  display:'inline-block', borderRadius: '3px',marginRight:'3px'}}></span>
                      <span style={{background: '#fff', width:'3px', height:'10px', display:'inline-block', borderRadius: '3px',marginRight:'3px'}}></span>
                      <span style={{background: '#fff', width:'3px', height:'15px', display:'inline-block', borderRadius: "3px",marginRight:'3px'}}></span>
                      <span style={{background: '#fff', width:'3px', height:'20px', display:'inline-block', borderRadius: '3px',marginRight:'3px',opacity:'0.5'}}></span>
                      <span style={{background: '#fff', width:'3px', height:'25px', display:'inline-block', borderRadius: '3px',marginRight:'3px',opacity:'0.5'}}></span>
                      {/* <SignalCellularAltIcon style={{color:'white' , margin:'7px', marginTop:'0px'}}/> */}
                  {data.robotStatus==="AVAILABLE" ? <PauseCircleIcon className="play-icon p-color-green"/>:<PlayCircleIcon className="play-icon p-color-red"/>}
              </div> 
        </div> 
      </div>
         }
         else {
          return null
         }
          })}
      </div>     
      </div>  
      <div className="SelectFleetPage-content-wrapper-Main">   
           {fleetlist?.map((data)=>{
          //  {console.log('fleetlist',fleetlist)}
               return (
                <div style={{width:'100%',marginTop:'20px'}}>
                    <h3 className='Dashboard-content-div-one-left-side-h3'>{data.fleetName}</h3>
                    <IndividualRobot data={data} latitude={data?.latitude} longitude={data?.longitude} />
               </div>
              ) })} 
      </div> 
 
    </div>
    </>  
  )
}

export default React.memo(SelectFleet)