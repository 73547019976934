import { useState } from "react";
import { useEffect } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline ,DirectionsRenderer} from "react-google-maps"
import { Polygon } from "react-google-maps";
import { robotIcon } from './robotIcon';
import MedianIncomeKml from "./testmap.kml";
import { KmlLayer } from "react-google-maps"
import ReactGoogleMapLoader from "react-google-maps-loader"
// import { DistanceMatrixService } from "@react-google-maps/api";
import * as React from 'react';
let directions=''
var image = {
  
}
let origin;
let destination;
let fivesec=true
let kmlurl= localStorage.getItem('kmlurl')
const googlemapcon=(props,item)=>{
  // console.log('Inside Direction Service')
  if(fivesec){
    
    const directionsService = new window.google.maps.DirectionsService();
    image={
     url: '/assets/images/robot-icon-pickup.svg',
     // url: '/assets/images/robot-icon-dropoff.svg',
     // size:new window.google.maps.Size(50, 50),
     scaledSize: new window.google.maps.Size(25, 25)
     }
    if(props.commandlistdata?.length >0){
      origin = { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude)};
      destination = { lat: parseFloat(props.robotlatitude), lng: parseFloat(props.robotlongitude)};
      directionsService.route(
       {   
         origin: origin,
         destination: destination,
         travelMode: window.google.maps.TravelMode.WALKING
       },
       (result, status) => {
         if (status === window.google.maps.DirectionsStatus.OK) {
           directions =result
         } else {
           console.error(`error fetching directions ${result}`);
         }
       }
     );
    }
    fivesec=false
    // console.log('inside 5 second interval')
}
    setInterval(()=>{
        fivesec=true
  }, 80000)
 
}

     
const options = {
  fillColor: "transparent",
  fillOpacity: 1,
  strokeColor: "#003300",
  strokeOpacity: 1,
  strokeWeight: 4,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1
}
const optionsteleop = {
    fillColor: "transparent",
    fillOpacity: 1,
    strokeColor: "rgb(220,53,69)",
    strokeOpacity: 1,
    strokeWeight: 4,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  }
  const optionsteleoptwo = {
    fillColor: "transparent",
    fillOpacity: 1,
    strokeColor: "#7859bc",
    strokeOpacity: 1,
    strokeWeight: 4,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  }
  
const robotpathoptions={
  fillColor: "transparent",
  fillOpacity: 1,
  strokeColor: "rgb(113, 207, 98)",
  strokeOpacity: 1,
  strokeWeight: 8,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1
}

const onLoad = polygon => {
  // console.log("polygon: ", polygon);
}

const GoogleMapMain = withScriptjs(withGoogleMap((props) =>
            <GoogleMap
            defaultZoom={15}
            defaultCenter={{lat:parseFloat(props.robotlatitude), lng:parseFloat(props.robotlongitude)}}   
            yesIWantToUseGoogleMapApiInternals
            > 
             <KmlLayer
               url={`${kmlurl}`}>
            </KmlLayer>
                {/* {console.log('kmlurlkmlurlkmlurlkmlurl',kmlurl)} */}
               {/* {props.commandlistdata?.length <=0 ?  */}
                  <Marker position={{lat:props.robotlatitude,lng:props.robotlongitude}} icon={{
                    url: '/assets/images/robot-icon-pickup_1.svg',
                    scaledSize: new window.google.maps.Size(30, 30),
                    }} 
                    >
                 </Marker>
                 {/* :null } */}
                 
                     <Polygon
                        onLoad={onLoad}
                        paths={props.nogozones}
                        options={options}
                      /> 
                     <Polygon
                        onLoad={onLoad}
                        paths={props.geofencezone}
                        options={optionsteleop}
                      /> 
                      {/* {console.log('props.fleetzones',props.fleetzones)}
                      {console.log('props.geofencezone',props.geofencezone)} */}
                    {props?.fleetzones?.map((item)=>{
                      return (  <Polygon
                                  onLoad={onLoad}
                                  paths={item}
                                  options={optionsteleoptwo} /> )
                      })} 
                     {/* {props.commandlistdata?.length >0?     
                      <Marker  position={{lat:parseFloat(props.commandlistdata[0]?.latitude),lng:parseFloat(props.commandlistdata[0]?.longitude)}} icon={{
                        url: '/assets/images/robot-icon-dropoff.svg',
                        scaledSize: new window.google.maps.Size(30, 30),
                        }}>
                      </Marker>:null}    
                      {props.commandlistdata?.length >0? 
                        <Polyline path={[{lat:parseFloat(props.commandlistdata[0]?.latitude), lng:parseFloat(props.commandlistdata[0]?.longitude)}, {lat: parseFloat(props.robotlatitude), lng:parseFloat(props.robotlongitude)}]} 
                        geodesic={true}
                        options={{
                            strokeColor: "#ed716c",
                            strokeOpacity: 0.75,
                            strokeWeight: 2,
                        }}
                          />
                      :null} */}
                      {props?.robotpathcheck || props?.commandlistdata?.map(item=> item.actionType ==="MOVELOCATION") ?  null:  <Polyline
                                     geodesic={true}
                                    onLoad={onLoad}
                                    path={props.robotpathmain}
                                    options={robotpathoptions}
              />}
              {/* {console.log('props.commandlistdata', props.commandlistdata)} */}
              {props.commandlistdata?.length >0 ?
                 props.commandlistdata.map((item)=>{
                if(item.actionPriority === 1){
                  return  <>  
                         {/* <Marker position={destination} icon={{
                            url: '/assets/images/robot-icon-pickup_1.svg',
                            scaledSize: new window.google.maps.Size(30, 30),
                            }}     /> */}
                          
                          
                            <Marker position={origin} icon={{
                                url: '/assets/images/robot-icon-dropoff_1.svg',
                                scaledSize: new window.google.maps.Size(30, 30),
                            }} />

                            {googlemapcon(props,item)}
                  
                  {/* {console.log('directions',directions,'destination',destination,'origin',origin)} */}
                  {props.robotpathcheck  && item.actionType ==="MOVELOCATION"?
                  <DirectionsRenderer
                  directions={directions}
                  options={{
                      suppressMarkers: true,
                      // markerOptions: { icon: image },
                      polylineOptions: {
                        stokeColor: "#ed716c",
                        strokeOpacity:0.5,
                        fill:'#ed716c',
                        strokeWeight: 10,
                      },
                  }}    
                  /> :  <Polyline
                  geodesic={true}
                  onLoad={onLoad}
                  path={props.robotpathmain}
                  options={robotpathoptions}
              />}
                  {/* <DistanceMatrixService 
                  options={{
                    destinations: [{ lat: parseFloat(props.commandlistdata[0]?.latitude), lng: parseFloat(props.commandlistdata[0]?.longitude)}],
                    origins: [{ lat: parseFloat(props.robotlatitude), lng: parseFloat(props.robotlongitude) }],
                    travelMode: "WALKING",
                  }}
                  callback = {(response) => {
                  console.log('Distance response',response)
                  localStorage.setItem('Distance', response.rows[0].elements[0].distance.text)
                  } }
                  /> */}
                  </>
                            }
                          })
                          :null}
                         
               
    </GoogleMap>
))

export default React.memo(GoogleMapMain)