import logo from './logo.svg';
import './App.css';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import { Navigate, Route, Routes } from 'react-router-dom';
import SelectFleet from './components/SelectFleet';
import { useEffect } from 'react';
var CryptoJS = require("crypto-js");
var Base64 = require("js-base64");
function App() {

  const token =localStorage.getItem('token')
       
  var data={
    email:'info@pringleapi.com',
    password:'pringlesoft@ottonomy',
    robotId:'OB02221009',
  }

  const encryptData = (data, secretKey) => {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey);
    const base64Encrypted = Base64.encode(encrypted.toString());
    return base64Encrypted;
  }
   
  
  // const decryptData=()=>{
  //   var bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_SECRET_LOGIN_PASSPHRASE);
  //   var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  //   console.log('decryptedData',decryptedData)
  // }

  useEffect(()=>{
    const encryptedData = encryptData(data, process.env.REACT_APP_SECRET_LOGIN_PASSPHRASE);
    console.log('encryptedData',encryptedData)
    // const decryptedData = decryptData(encryptedData, process.env.REACT_APP_SECRET_LOGIN_PASSPHRASE);
    // console.log('decryptedData',decryptedData)
  },[])
    return (
      <div className="App">
                   
                    <Routes>              
                       <Route path="/" element={<Login/>}/>
                       <Route path={"dashboard/:id"} element={<Dashboard/>} />
                       <Route path={"/:encrypt"} element={<Login/>} />
                       <Route path={ 'selectfleet/:email'} element={<SelectFleet/>} />
                   </Routes>
      </div>
     
    );
  }



export default App;
