import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {toast } from 'react-toastify'
import {  cancelActionAPI, cancelOrderAPI, getorderstatus, getrobotStatus, robotactionapi, UpdateRobotAction } from '../API'
import ringer from "../components/alert.wav";
import CancelActionModel from './CancelActionModel';
import RobotActionModal from './RobotActionModal';
import io from 'socket.io-client';
function RobotActions(props) {
  var boxcolororange=false
  let socket
  // const serverUrl = 'http://localhost:3000';
  // const serverUrl ="https://goggoapi.ottonomy.io/"
  const audio = new Audio(ringer);
  // audio.loop = true;
  const [orderStatusvar, setorderStatusvar] =useState()
  const playbeepsound=()=>{
    function play(){
      audio.play() 
      audio.loop = true
      audio.muted =false
      // console.log(';sdsbfbndn audio')
    } 
     play() 
   }
   const pausebeepsound=()=>{
    function pause(){
      audio.pause() 
      audio.loop = false
      audio.muted =true
      audio.src=''
      // console.log('ELement',document.getElementsByTagName('audio')[0].attributes('src'))
      // console.log('sdsbfbndn pause',audio)
    } 
      pause() 
   } 

  const token = localStorage.getItem('token')
  const [robotData, setrobotData] =useState()
  // const robotId = localStorage.getItem('robotId')
  var robotId=props.robotId
  const[commandlistdata, setcommandlistdata] =useState([])
  const [orderId, setorderId] = useState()
  const [actionId, setactionId] =useState()
  const [finishbtn,setfinishbtn] =useState(true)
  var actionPriorityarray
  var leastpriority=1
  useEffect(()=>{
    commandlistdata.map((item)=>{
     if(item.actionPriority<leastpriority){
      leastpriority=item.actionPriority
     }else{
      leastpriority=leastpriority
     }

    })
    console.log('leastpriority',leastpriority)
  let finalrobotId =robotId 
    const robotcommand= setInterval(()=>{
      robotactionapi(finalrobotId,token)
      .then((res)=>{
        // console.log('CommandList Data', res)
        if(res.data.message ==='Malformed request' || res.data ==='' ||res.status ===205 || res.status===204){
          return null
        } 
        else{
          setcommandlistdata(res.data.robotCommandList)
        }               
        let orderiii= res.data.robotCommandList[0].orderId
        let actionId =  res.data.robotCommandList[0].actionId
        setorderId(orderiii)
        setactionId(actionId)
      })
      .catch((err)=>{
        // console.log('Robot Actions Error' ,err)
      })
    }, 2000)

    return () =>  {
      clearInterval(robotcommand)
   }; 
  },[robotId])



 const getrobotactionStatus=(commandlistdata,orderId,actionId)=>{
  // console.log('commandlistdata?.actionStatus',commandlistdata?.actionStatus)

  // console.log('commandlistdata?.actionStatus',commandlistdata?.actionStatus)
switch(commandlistdata?.actionStatus){
    case "0" :
      // playbeepsound()
      return ( <div className='RobotActions-button-wrapper'>
                <audio preload="auto" loop autoplay="autoplay" id="playaudio">
                  <source src="/static/media/alert.aa9b8554cbeaf7968e81.wav" type="audio/wav"></source>
              </audio>
                 <RobotActionModal name="Start" actionId={actionId} orderId={orderId} number="1" robotId={props.robotId} commandlistdata={commandlistdata}/>
              {/* <button className="RobotActions-Start-btn" onClick={()=>handlerobotAction("1" , actionId,orderId)}>Start Action</button> */}
              {/* <button className="RobotActions-cancel-btn" onClick={()=> handleCancelActionbutton(props?.robotData?.robotStatus?.actionId,orderId)}> Cancel Action</button>*/}
                {/* <CancelActionModel actionId={props?.robotData?.robotStatus?.actionId}  orderId={orderId} fleetId={props?.robotData?.robotStatus?.fleetId} name="Cancel" commandlistdata={commandlistdata}/>  */}
          </div>);
    case null:
      // playbeepsound()
      return ( <div className='RobotActions-button-wrapper'>
                  <audio preload="auto" loop autoplay="autoplay" id="playaudio">
                      <source src="/static/media/alert.aa9b8554cbeaf7968e81.wav" type="audio/wav"></source>
                  </audio>
                  <RobotActionModal name="Start" actionId={actionId} orderId={orderId} number="1" robotId={props.robotId} commandlistdata={commandlistdata}/>
                   {/* <button className="RobotActions-Start-btn" onClick={()=>handlerobotAction("1" , actionId,orderId)}>Start Action</button> */}
                  {/* <CancelActionModel actionId={props?.robotData?.robotStatus?.actionId}  name="Cancel"  orderId={orderId} fleetId={props?.robotData?.robotStatus?.fleetId} commandlistdata={commandlistdata}/> */}
            </div>);
    case "1":
      return (<div className='RobotActions-button-wrapper'> 
               <RobotActionModal name="Finish" actionId={actionId} orderId={orderId} number="3" robotId={props.robotId} commandlistdata={commandlistdata}/>
             {/* <button className="RobotActions-Start-btn" onClick={()=>handlerobotAction("3",actionId,orderId)}>Finish Action</button> */}
             {/* <button className="RobotActions-cancel-btn" onClick={()=> handleCancelActionbutton(props?.robotData?.robotStatus?.actionId,orderId)}> Cancel Action</button> */}
                {/* <CancelActionModel actionId={props?.robotData?.robotStatus?.actionId} name="Cancel" orderId={orderId} fleetId={props?.robotData?.robotStatus?.fleetId} commandlistdata={commandlistdata}/> */}
            </div>);
     case "4":
        return (<div className='RobotActions-button-wrapper'> 
          <RobotActionModal name="Finish" actionId={actionId} orderId={orderId} number="3" robotId={props.robotId} commandlistdata={commandlistdata}/>
        {/* <button className="RobotActions-Start-btn" onClick={()=>handlerobotAction("3",actionId,orderId)}>Finish Action</button> */}
        {/* <button className="RobotActions-cancel-btn" onClick={()=> handleCancelActionbutton(props?.robotData?.robotStatus?.actionId,orderId)}> Cancel Action</button> */}
          {/* <CancelActionModel actionId={props?.robotData?.robotStatus?.actionId} name="Cancel" orderId={orderId} fleetId={props?.robotData?.robotStatus?.fleetId} commandlistdata={commandlistdata}/> */}
      </div>);    
     default:
      return null
  }
 } 
const getouterborder=(commandlistdata)=>{
  switch(commandlistdata[0]?.actionStatus){
    case "4":
      return 'RobotActions-content-wrapper-div orange_border'
    default:
      return 'RobotActions-content-wrapper-div '  
  }
  
}

const orderStatus=useCallback((orderId)=>{
   getorderstatus(orderId, token).then((res)=>{
    // console.log('GetOrderStatus Response',res)
    setorderStatusvar(res.data.orderStatus)
  })
  .catch((err)=>{
   console.log('GetOrderStatus Error',err)
  })
},[])
  return (
    <div className={getouterborder(commandlistdata)}>
                  
      {/* {console.log('commandlistdatacommandlistdata',commandlistdata)} */}
      {(()=>{
          setTimeout(()=>{
            setfinishbtn(true)
          }, 7000)
      })()}
      {/* {console.log('commandlistdatafdgnfnfhfm',commandlistdata)} 
      
      mesaage===MOVEHOME then Ottobot Enroute to Home LOcation
      else
      call getorderstatus
      Ottobot then print order status 
            {
          "status": 200,
          "orderId": "968696986986",
          "orderStatus": "order delivered",
          "statusCode": 9,
          "estimatedDelivery": 3592,
          "estimatedPickup": 895,
          "robotId": "OB02221009",
          "currentLat": "40.699048",
          "currentLon": "-89.613027"
      }
      */}
      {commandlistdata?.length >0 ?
         commandlistdata.map((item)=>{
            // if(item.actionPriority === leastpriority){
             return <><h3> Ottobot {item.message==='MOVEHOME' ? <> Enroute to Home Location</>: <>{orderStatus(item.orderId)}<>{orderStatusvar}</></>}</h3>
                 <p> Order Id : <span>{item.orderId}</span></p> 
                 <p> Action Id : <span>{item.actionId}</span></p> 
                  {item.actionStatus===null || item.actionStatus===undefined ? null:<p> Action Status : <span>{item.actionStatus}</span></p> }  
                {/* <p> Location :    {item.latitude ||item.longitude ?<span>{item.latitude}, {item.longitude}</span>:item.locationName }  </p>  */}
                {item.actionType==="MOVELOCATION" ? '':<p> Compartement:<span className=''>{item.boxId}</span></p>} 
                {getrobotactionStatus(item,item.orderId,item.actionId)} 
                {(()=>{
                        if(item.actionStatus==='4'){
                           boxcolororange=true
                        }
                        else{
                           boxcolororange=false
                        }
                })()}
             {(()=>{
                 if(item?.actionStatus ==='1'){
                  pausebeepsound()
                 }
                })()
             }
    
              </>  
            // }
      }) 
      : <p> No actions to show</p>}

    </div>
  )
}

export default React.memo(RobotActions)